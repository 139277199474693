@tailwind base;
@tailwind components;
@tailwind utilities;
body,
html {
    height: 100%;
    margin: 0;
    overflow: hidden;
    user-select: none;
}

button:focus,
svg:focus {
    outline: unset;
}

div[id^=osmdCanvasPage] {
    align-items: center;
    display: flex;
}

/* Hide obsolete triangle of substituted on-boarding tooltip. */
div.__floater {
    opacity: 0 !important;
    transition: unset !important;
}

div.ant-notification-notice-message {
    align-items: center;
    display: flex;
}

label.ant-form-item-required {
    align-items: unset !important;
}

label.ant-form-item-required::before {
    font-size: 20px !important;
    line-height: 1.4 !important;
}

span.ant-pagination-item-ellipsis {
    position: unset !important;
}

#color-picker .ant-popover-inner-content {
    padding: 0 !important;
}

#deviation-percentage-slider .ant-popover-inner-content {
    padding: 0 0.5rem !important;
}

#melodic-operators::-webkit-scrollbar,
#music-sheet-pages::-webkit-scrollbar,
#pattern-list::-webkit-scrollbar {
    display: none;
}
